const inIframe = function () {
	try {
		return window.self !== window.top;
	} catch (e) {
		return true;
	}
};

const config = {
	addons: [],

	dataDomain:
		typeof document === 'undefined'
			? ''
			: document.location.port == 8080
			? 'http://aaincschool.test:8000'
			: '',

	hideHeader: (location) => {
		return inIframe();
	},

	hideFooter: (location) => {
		return true;
	},
};

export default config;
