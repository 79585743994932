import asyncComponent from 'Demora/AsyncComponent';

const Payments = asyncComponent(() =>
	import(/* webpackChunkName: "prot-auth-settings" */ '../../js/payments')
);

const routes = [
	{
		path: '/payments',
		component: Payments,
	},
];

export default routes;
