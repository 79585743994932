import asyncComponent from 'Demora/AsyncComponent';

const Commission = asyncComponent(() =>
	import(
		/* webpackChunkName: "prot-auth-settings" */ '../../js/home/money/commission'
	)
);

const Purchases = asyncComponent(() =>
	import(
		/* webpackChunkName: "prot-auth-settings" */ '../../js/home/money/purchases'
	)
);

const PrelaunchVIP = asyncComponent(() =>
	import(
		/* webpackChunkName: "prot-auth-settings" */ '../../js/home/PrelaunchVIP'
	)
);

const PayWiseCommission = asyncComponent(() =>
	import(
		/* webpackChunkName: "prot-auth-settings" */ '../../js/admin/PayWiseCommission'
	)
);

const routes = [
	{
		path: '/home/money/commission',
		component: Commission,
	},
	{
		path: '/home/money/purchases',
		component: Purchases,
	},
	{
		path: '/home/prelaunchVIP',
		component: PrelaunchVIP,
	},
];

export default routes;
